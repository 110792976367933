import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { FactService } from 'src/app/core/services/fact.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { AppState } from 'src/app/store/app.state';
import { getFacts } from 'src/app/store/fact/fact.actions';
import { Fact } from 'src/app/store/fact/fact.model';
import { selectFactCount, selectFacts } from 'src/app/store/fact/fact.selectors';
import { Notification } from 'src/app/store/notification/notification.model';
import { selectNotification } from 'src/app/store/notification/notification.selectors';
import { User } from 'src/app/store/user/user.model';
import { FactDeleteComponent } from '../fact-delete/fact-delete.component';
import { FilterModalComponent } from './components/filter-modal/filter-modal.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
})
export class HomePage implements OnInit {
  hasFacts = false;
  notification$: Observable<any> = this.store.select(selectNotification);
  facts$: Observable<Fact[]> = this.store.select(selectFacts);
  factCount$: Observable<number> = this.store.select(selectFactCount);
  currentUser: User;
  nomenclatures: any;
  nomenclature = '';
  page = 1;
  limit = 10;
  id = '';
  networkId = '';
  startDate = '';
  endDate = '';

  constructor(
    private store: Store<AppState>,
    private storageService: StorageService,
    private factService: FactService,
    private modalController: ModalController,
    private router: Router,
    private notificationService: NotificationService) {
    this.nomenclatures = this.factService.getNomenclature();
    this.factCount$.subscribe((count) => this.hasFacts = count > 0);
    this.notification$.subscribe(async (notification: Notification) => {
      this.notificationService.show(notification);
      if (notification && notification.type === 'SUCCESS') {
          this.refreshData();
        }
    });
  }

  async ngOnInit() {
    this.currentUser = new User(await this.storageService.get('User'));
    this.refreshData();
  }

  async onFilterClick() {
    const currentQuery = {
      id: this.id,
      nomenclature: this.nomenclature,
      networkId: this.networkId,
      startDate: this.startDate,
      endDate: this.endDate
    }
    const modal = await this.modalController.create({
      component: FilterModalComponent,
      componentProps : { currentQuery }
    });

    modal.onDidDismiss().then((data) => {
      const query = data['data'];
      if (query) {
        this.id = query.id;
        this.nomenclature = query.nomenclature;
        this.networkId = query.networkId;
        this.startDate = query.startDate;
        this.endDate = query.endDate;
        this.refreshData();
      }
    });

    await modal.present();
  }

  async onUpdateFact(fact: Fact) {
    this.router.navigate(['/fait/modification'], {state: { data: fact }, replaceUrl: true});
  }

  async onDeleteFact(fact: Fact) {
    const modal = await this.modalController.create({
      component: FactDeleteComponent,
      cssClass: 'auto-height',
      componentProps : { fact }
    });

    await modal.present();
  }

  onPageChanged(event) {
    this.page = event;
    this.refreshData();
  }

  onPageSizeChanged(event) {
    this.page = 1;
    this.limit = event.target.value;
    this.refreshData();
  }

  refreshData() {
    this.store.dispatch(getFacts({
      page: this.page,
      limit: this.limit,
      id: this.id,
      nomenclature: this.nomenclature,
      networkId: this.networkId,
      startDate: this.startDate,
      endDate: this.endDate
    }));
  }
}
