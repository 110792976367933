import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { AccessibilityPage } from './pages/accessibility/accessibility.page';
import { FactCreatePage } from './pages/fact-create/fact-create.page';
import { FactUpdatePage } from './pages/fact-update/fact-update.page';
import { HomePage } from './pages/home/home.page';
import { LegalPage } from './pages/legal/legal.page';
import { LoginPage } from './pages/login/login.page';

const routes: Routes = [
  {
    path: '',
    data: {
      breadcrumb: `App'ISIS`
    },
    children: [
      {
        path: '',
        component: LoginPage
      },
      {
        path: 'accueil',
        component: HomePage,
        data: {
          breadcrumb: 'Accueil'
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'mentions-legales',
        component: LegalPage,
        data: {
          breadcrumb: `Mentions légales`
        },
      },
       {
        path: 'accessibilite',
        component: AccessibilityPage,
        data: {
          breadcrumb: `Accessibilité`
        },
      },
      {
        path: 'faits',
        redirectTo: 'accueil'
      },
      {
        path: 'fait/ajout',
        component: FactCreatePage,
        data: {
          breadcrumb: `Saisi d'un fait`
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'fait/modification',
        component: FactUpdatePage,
        data: {
          breadcrumb: `Modification d'un fait`
        },
        canActivate: [AuthGuard],
      },
      {
        path: '**',
        redirectTo: ''
      },
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
