import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-assistance',
  templateUrl: './assistance.component.html',
})
export class AssistanceComponent {

  constructor(private modalController: ModalController) { }

  onDismiss() {
    this.modalController.dismiss();
  }
}
