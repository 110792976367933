import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { FactUpdatePage } from './fact-update.page';
import { RouterModule } from '@angular/router';
import { FactPageRoutingModule } from '../fact-create/fact-create-routing.module';
import { AddPerpetratorModalComponent } from './components/add-perpetrator-modal/add-perpetrator-modal.component';
import { AddProtagonistModalComponent } from './components/add-protagonist-modal/add-protagonist-modal.component';
import { AddVictimModalComponent } from './components/add-victim-modal/add-victim-modal.component';
import { AddGoodModalComponent } from './components/add-good-modal/add-good-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    IonicModule,
    RouterModule,
    FactPageRoutingModule,
    ReactiveFormsModule
  ],
  declarations: [
    FactUpdatePage,
    AddPerpetratorModalComponent,
    AddProtagonistModalComponent,
    AddVictimModalComponent,
    AddGoodModalComponent,
  ]
})
export class FactUpdatePageModule {}
