import { Component, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/core/services/user.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { User } from 'src/app/store/user/user.model';
import { HttpClient } from '@angular/common/http';
import { ModalController } from '@ionic/angular';
import { AssistanceComponent } from './components/assistance/assistance.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit  {
  isAuthenticated$: Observable<boolean> = this.userService.isAuthenticated();
  currentUser: User;
  isAdmin: boolean;
  isSupervisor: boolean;
  isManager: boolean;
  version: string;

  constructor(
    private userService: UserService,
    private renderer: Renderer2,
    private header: ElementRef,
    private httpClient: HttpClient,
    private modalController: ModalController,
    private storageService: StorageService) { }

  async ngOnInit() {
    this.currentUser = new User(await this.storageService.get('User'));
    this.isAdmin = this.currentUser && this.currentUser.isAdmin;
    this.isSupervisor = this.currentUser && this.currentUser.isSupervisor;
    if (!this.version) {
      this.httpClient
        .get('assets/version.txt', { responseType: 'text' })
        .subscribe((version: string) => {
        this.version = version;
      })
    }
  }

  logout() {
    this.userService.logout();
  }

  async assistance() {
    const modal = await this.modalController.create({
      component: AssistanceComponent,
      cssClass: 'auto-height',
      id: 'assistance-modal'
    });
    
    await modal.present();
  }

  @HostListener("click", ['$event'])
  onClick(event: any){
    const button = event.target as HTMLElement;
    if (button.id == 'AssistanceButton') {
      this.assistance();
    } else if (button.id == 'DeconnectionButton') {
      this.logout();
    }
  }
}
