import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';
import { NgxPaginationModule } from 'ngx-pagination';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from './store/user/user.effects';
import { StoreModule } from '@ngrx/store';
import * as fromUser from './store/user/user.reducers';
import * as fromFact from './store/fact/fact.reducers';
import * as fromNotification from './store/notification/notification.reducers';
import { LoginPageModule } from './pages/login/login.module';
import { SharedModule } from './modules/shared/shared.module';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { HttpRequestInterceptor } from './core/interceptors/http-request-interceptor';
import { FactCreatePageModule } from './pages/fact-create/fact-create.module';
import { FactEffects } from './store/fact/fact.effects';
import { FactUpdatePageModule } from './pages/fact-update/fact-update.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { LegalPageModule } from './pages/legal/legal.module';
import { AccessibilityPageModule } from './pages/accessibility/accessibility.module';

registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule,
    IonicModule.forRoot(),
    NgxPaginationModule,
    AppRoutingModule,
    HttpClientModule,
    LoginPageModule,
    NgSelectModule,
    LegalPageModule,
    AccessibilityPageModule,
    FactCreatePageModule,
    FactUpdatePageModule,
    RouterModule,
    SharedModule,
    StoreModule.forRoot({}),
    StoreModule.forFeature(fromNotification.featureKey, fromNotification.reducer),
    StoreModule.forFeature(fromUser.featureKey, fromUser.reducer),
    StoreModule.forFeature(fromFact.featureKey, fromFact.reducer),
    IonicStorageModule.forRoot({
      name: '_appisisdb',
      driverOrder: [ Drivers.LocalStorage ]
    }),
    EffectsModule.forRoot([UserEffects, FactEffects]),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr' },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
